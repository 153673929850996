export default {
    isLoggedIn: false,
    profile: {},
    users:[],
    user: [],
    userPackage: [],
    userAccess: [],
    profileAccess: [],
    partnerAccess: [],
    prospects: [],
    prospectsBasket: [],
    prospect: [],
    newProspects: [],
    countProspects: 0,
}
